import Map from './Maps';
import Header from './Header';
import React, { useState } from 'react';

function App() {
    const [menu_visibility, setVisibility] = useState(true);

    const toggleMenu = () => {
        setVisibility(!menu_visibility);
    };

    return (
        <div>
            <Header toggleMenu={toggleMenu} />
            <Map menu_visibility={menu_visibility} />
        </div>
    );
}

export default App;
