import React, { useEffect, useState } from 'react';

function Dropdown({ onData }) {
    const [routes_list, setRoutes] = useState([]);
    const [selected_option, setOption] = useState('');

    const handleChange = (event) => {
        setOption(event.target.value);
        onData(event.target.value);
    };

    useEffect(() => {
        const temp_route_list = [];

        fetch('./route.geojson')
            .then((response) => response.json())
            .then((json) => {
                const json_features = json['features'];
                for (let lines in json_features) {
                    if (
                        json_features[lines]['geometry']['type'].includes(
                            'LineString'
                        )
                    ) {
                        let bus_object = json_features[lines]['properties'];
                        temp_route_list.push(
                            <option value={bus_object['route_id']}>
                                {bus_object['route_name']}
                            </option>
                        );
                    }
                }
                setRoutes(temp_route_list);
            });
    }, []);

    return (
        <div>
            <select value={selected_option} onChange={handleChange}>
                <option value="">バス路線を選択する</option>
                {routes_list}
            </select>
        </div>
    );
}

export default Dropdown;
